<template>
  <v-row align="center" justify="end" class="mt-0" no-gutters>
    <v-card-actions class="justify-end mb-2 mx-0 pt-0 pr-0">
      <v-spacer></v-spacer>
      <!-- <div v-if="refreshingButtons[index] ? true : false">
              <v-progress-circular
                size="24"
                indeterminate
                color="primary"
                key="index"
              ></v-progress-circular>
              {{ refreshingButtons[index] }}
            </div>
            <div v-else> -->
      <v-btn
        key="index"
        icon
        :disabled="refreshButtons[index] ? true : false"
        @click="refreshMyVote(vote, index)"
      >
        <v-icon color="grey lighten-1"> mdi-refresh </v-icon>
        <svg-icon type="mdi" :path="refreshPath" size="16" color="grey lighten-1"></svg-icon>
      </v-btn>
      <!-- </div> -->
      <v-btn icon>
        <!-- <v-icon>mdi-chart-multiline</v-icon> -->
        <svg-icon type="mdi" :path="chartMultilinePath" size="16"></svg-icon>
      </v-btn>
      <v-btn icon @click="toVote(vote)">
        <!-- <v-icon>mdi-vote</v-icon> -->
        <svg-icon type="mdi" :path="votePath" size="16"></svg-icon>
      </v-btn>
    </v-card-actions>
  </v-row>
</template>

<script>
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiChartLine, mdiRefresh, mdiVote } from "@mdi/js";

export default {
  name: "VoteActionBottomCard",
  components: {
    SvgIcon,
  },  
  props: ["vote", "index"],
  data: () => ({
    refreshButtons: [],
    refreshingButtons: [],
    chartMultilinePath: mdiChartLine,
    refreshPath: mdiRefresh,
    votePath: mdiVote
  }),
  methods: {
    toVote(vote) {
      this.$store.dispatch("vote/selectVote", vote);
      let paramsForRoute = vote.route.params;
      paramsForRoute.id = vote.id;
      this.$router.push({
        name: vote.route.name,
        params: paramsForRoute,
      });
    },
    async refreshMyVote(vote, index) {
      this.refreshButtons[index] = true;
      // this.refreshingButtons[index] = true;
      // var self = this;
      // console.log("this.refreshingButtons[index] 1 ", this.refreshingButtons[index]);
      //       new Promise(function(resolve, reject) {
      //         setTimeout(() => {
      //           //self.refreshButtons[index] = true;
      //            self.refreshingButtons[index] = true;
      //         console.log("this.refreshingButtons[index] 2 ", self.refreshingButtons[index], index);
      //   resolve("done");
      //                 }, 3000)
      // });

      await this.$store.dispatch("user/refreshMyVote", {
        vote: vote,
        index: index,
      });
      // new Promise(function (resolve, reject) {
      //   self.refreshingButtons[index] = false;
      //   console.log(
      //     "this.refreshingButtons[index] 3 ",
      //     self.refreshingButtons[index]
      //   );
      //   self.refreshingButtons = [...self.refreshingButtons];
      //   resolve("done");
      // });

      // console.log("this.refreshingButtons[index] 3 ", this.refreshingButtons[index]);
      // this.refreshingButtons[index] = false;
      // console.log("this.refreshingButtons[index] 4 ", this.refreshingButtons[index]);
    },
  },
};
</script>

<style></style>
