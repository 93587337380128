<template>
  <v-row align="center" no-gutters class="mt-2">
    <v-col
      cols="12"
      class="text-no-wrap mr-2"
      style=""
      v-resize-text="{
        ratio: 1.5,
        minFontSize: '10px',
        maxFontSize: '14px',
        delay: 100,
      }"
    >
      mon vote {{ vote.choice[choice].name }} :
      {{ vote.choice.one == 0 && vote.choice.two == 0 ? "Vote blanc" : "" }}
    </v-col>
    <v-col cols="undefined">
      <v-progress-linear
        v-if="vote.choice"
        :color="'rgba(' + r + ',' + g + ',' + b + ',1)'"
        label="name"
        height="5"
        rounded
        :value="vote[choice]"
      ></v-progress-linear>
    </v-col>
    <v-col
      cols="3"
      style="max-width: 60px"
      class="d-flex justify-end text-no-wrap caption"
    >
      <div
        v-if="vote.choice"
        class=""
        v-resize-text="{
          ratio: 1.5,
          minFontSize: '10px',
          maxFontSize: '14px',
          delay: 100,
        }"
      >
        {{ vote[choice] }}.00 %
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "MyChoice",
  props: ["vote", "choice"],
  computed: {
    r: function () {
      if (this.choice == "one")
        return 255 - Math.round((222 / 100) * this.vote.one);
      if (this.choice == "two")
        return 255 - Math.round((11 / 100) * this.vote.two);
      else return 255;
    },
    g: function () {
      if (this.choice == "one")
        return 255 - Math.round((105 / 100) * this.vote.one);
      if (this.choice == "two")
        return 255 - Math.round((188 / 100) * this.vote.two);
      else return 255;
    },
    b: function () {
      if (this.choice == "one")
        return 255 - Math.round((12 / 100) * this.vote.one);
      if (this.choice == "two")
        return 255 - Math.round((201 / 100) * this.vote.two);
      else return 255;
    },
  },
};
</script>
