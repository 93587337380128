<template>
  <div>
    <v-card class="mx-auto mb-2 d-flex align-stretch flex-column">
      <v-row no-gutters align="center" justify="center" class="py-1">
        <h4 style="word-break: normal">Mes votes</h4>
      </v-row>
    </v-card>
    <v-card class="mx-auto mb-2 d-flex align-stretch flex-column">
      <div v-if="!isUserVotes && refreshInitMessage" class="ma-2 pa-2">
        <h4>
          On t'attend pour changer le monde.<v-btn text to="/france"
            >Vote</v-btn
          >
        </h4>
      </div>
    </v-card>

    <v-card
      class="mx-auto mb-2 d-flex align-stretch flex-column"
      v-for="(vote, index) in userVotes"
      :key="vote.id"
    >
      <v-row v-if="vote.hasOwnProperty('choice')" no-gutters align="start" justify="start" class="pt-1">
        <v-col
          cols="1"
          class="d-flex flex-column caption font-weight-light justify-end"
        >
          <!-- <VoteActionLeftCard :vote="vote" :index="vote.id" /> -->
        </v-col>

        <v-col cols="11" class="mt-1 pr-4">
          <v-card-title primary-title class="pa-0 subtitle-2 mb-auto">
            <v-col cols="12" class="pl-1">
              <v-row>
                <v-col cols="9" class="pt-0">
                  <h3 style="word-break: normal">
                    {{ vote.name }} ( {{ (vote.zoneName == "") ? "" :vote.zoneName + " - " }}
                    {{ vote.themeName }} )
                  </h3>
                </v-col>
              </v-row>
            </v-col>
          </v-card-title>
          <v-card-text class="pa-0 subtitle-2 mb-auto">
            <VoteLineBarChoiceResult :vote="vote" choice="one" color="blue" />
            <MyChoice choice="one" :vote="vote" />
            <VoteLineBarChoiceResult :vote="vote" choice="two" color="red" />
            <MyChoice choice="two" :vote="vote" />
            <VoteListCategory :voteCategory="vote.category" :voteSource="vote.url"/>
          </v-card-text>
          <VoteActionBottomCard :vote="vote" :index="index" />
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
//TODO ou est passé refreshInitMessage ??????????
import { mapState } from "vuex";
import VoteActionLeftCard from "@/component/user/ui/VoteActionLeftCard.vue";
import MyChoice from "@/component/user/ui/MyChoice.vue";
import VoteListCategory from "@/component/vote/voteItem/ui/VoteListCategory.vue";
import VoteActionBottomCard from "@/component/user/ui/VoteActionBottomCard.vue";
import VoteLineBarChoiceResult from "@/component/vote/ui/VoteLineBarChoiceResult.vue";

export default {
  name: "MyVote",
  components: {
    VoteActionLeftCard,
    MyChoice,
    VoteListCategory,
    VoteActionBottomCard,
    VoteLineBarChoiceResult,
  },
  data: () => ({
    refreshButtons: [],
    voteStatus: "vote",
    initialMyVote: {
      one: 0,
      two: 0,
    },
    dialog: false,
  }),
  methods: {
    isUserVotes() {
      return this.userVotes && this.userVotes.length > 0;
    },
    vote() {
      this.$router.push({ name: "country", params: { worldCountry: "fr" } });
    },
  },
  computed: {
    ...mapState("user", ["userVotes"]),
    // userVotesz: function (userVotes) {
    //   console.log(userVotes);
    //   return userVotes
    //   // return userVotes.filter((userVotes) =>
    //   //   userVotes.hasOwnProperty("choice")
    //   // );
    // },
  },
};
</script>
