<template>
  <div>
    <router-view :class="{ 'has-alert': !isOnline }" />
    <v-btn
      v-scroll="onScroll"
      v-show="fab"
      fab
      dark
      fixed
      x-small
      bottom
      right
      color="black"
      @click="toTop"
    >
      <!-- <v-icon>mdi-chevron-up</v-icon> -->
      <svg-icon type="mdi" :path="chevronUpPath" size="16" right></svg-icon>
    </v-btn>
  </div>
</template>

<script>
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiChevronUp } from "@mdi/js";

export default {
  name: "Account",
  components: {
    SvgIcon,
  },
  data: () => ({
    fab: false,
    chevronUpPath: mdiChevronUp,
  }),
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
};
</script>

<style scoped>
.has-alert {
  margin-top: 60px !important;
}
</style>
