<template>
  <v-container>
    <v-card v-if="currentUser" class="mx-auto" max-width="320">
      <v-card-title class="justify-center"><h4>Bienvenue Bob</h4></v-card-title>
      <v-card-text>
        <v-row align="center" class="mx-0 justify-center">
          <div class="grey--text ms-4">
            <p class="text-center my-0">
              Cette carte te donne le droit d'être :
            </p>
            <ul>
              <li>qui tu veux être, qui que tu sois</li>
              <li>quel que soit ce que tu as</li>
              <li>quoi que tu aies pu faire</li>
            </ul>
            <p class="mt-2 text-center">
              Change le présent.<br />
              Décide de vivre.
            </p>
          </div>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-list dense>
        <v-list-item>
          <v-list-item-content class="caption">E-mail</v-list-item-content>
          <v-list-item-content
            class="align-end text-end grey--text ms-4 caption justify-end"
            >{{ currentUser.email }}</v-list-item-content
          >
        </v-list-item>
        <v-list-item
          v-if="currentUser.metadata && currentUser.metadata.creationTime"
        >
          <v-list-item-content class="caption"
            >Création de la carte</v-list-item-content
          >
          <v-list-item-content
            class="align-end text-end grey--text ms-4 caption justify-end"
            >{{
              currentUser.metadata.creationTime | moment("LLLL")
            }}</v-list-item-content
          >
        </v-list-item>
        <v-list-item
          v-if="currentUser.metadata && currentUser.metadata.lastSignInTime"
        >
          <v-list-item-content class="caption"
            >Dernière utilisation</v-list-item-content
          >
          <v-list-item-content
            class="align-end text-end grey--text ms-4 caption justify-end"
            >{{
              currentUser.metadata.lastSignInTime | moment("from")
            }}</v-list-item-content
          >
        </v-list-item>
        <v-list-item>
          <v-list-item-content class="caption"
            >Compte vérifié ?</v-list-item-content
          >
          <v-list-item-content
            class="align-end text-end grey--text ms-4 caption justify-end"
            >{{
              currentUser.emailVerified ? "Oui" : "Non"
            }}</v-list-item-content
          >
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-card-text>
        <v-row align="center" class="mx-0 justify-center">
          <p class="text-center my-0">On t'attend pour changer le monde.</p>
          <v-btn class="my-2" text @click="backToVote()">
            <svg-icon type="mdi" :path="votePath" size="16"></svg-icon>

            <span class="ml-2">Vote</span></v-btn
          >
        </v-row>
        <v-row align="center" class="mx-0 justify-center">
          <v-btn class="my-2" x-small text @click="faq()">
            <svg-icon type="mdi" :path="questionPath" size="16"></svg-icon>

            <span class="ml-2">Comment ça marche ?</span></v-btn
          >
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import Vue from "vue";
import VueMomentPlugin from "vue-moment";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiVote, mdiFrequentlyAskedQuestions } from "@mdi/js";
const moment = require("moment");
require("moment/locale/fr");

export default {
  name: "MyCard",
  data: () => ({
    questionPath: mdiFrequentlyAskedQuestions,
    votePath: mdiVote,
  }),
  components: { SvgIcon },
  methods: {
    faq() {
      this.$store.commit("openFaq", true);
    },
    backToVote() {
      if (
        sessionStorage.getItem("redirectPath") == "" ||
        sessionStorage.getItem("redirectPath") == null
      ) {
        this.$router.push({
          name: "world",
        });
      } else {
        this.$router.push({ path: sessionStorage.getItem("redirectPath") });
        sessionStorage.removeItem("redirectPath");
      }
    },
  },
  computed: mapState("user", ["currentUser"]),
  created() {
    Vue.use(VueMomentPlugin, { moment });
  },
};
</script>
